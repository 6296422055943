* {
  margin: 0;
  padding: 0;
}

/* Style the navigation menu */
.topnav {
  overflow: hidden;
  background-color: black;
  position: relative;
}


.topnav > ul {
  list-style: none;
  display: flex;
  justify-content: center;
  color: white;
}

.topnav > ul > li {
  margin: 1em;
}

.mobileNavButton {
    display: flex;
    width: fit-content;
    margin: 8px 0 0 90%;
    height: 23px;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 8px;
}

.mobileNavButtonLines {
  height: 3px;
  width: 20px;
  background: white;
  display: block;
}

.mobileMenuLinks {
    display: flex;
    background: black;
    flex-direction: column;
    justify-content: center;
    padding: 1em 8%;
}

.mobileMenuLinks > li {
    text-align: right;
}